








































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel

  mounted() {
    setTimeout(() => {
      if (!this.setting.rules.length) {
        this.$router.push(`/community-organize/dao-detail/${this.setting.daoId}/setting/dao-settings?focusField=rule`)
      }
    }, 500)
  }
}
